<template>
  <b-container :class="$style.wrap" class="dflex align-center valign-center">
    <b-row>
      <b-col :class="$style.imageWrap" cols="12" md="6" class="dflex align-center valign-center">
        <img src="../assets/img/404.svg" alt="Страница не найдена" :class="$style.image" />
      </b-col>
      <b-col cols="12" md="6" :class="$style.contentWrap">
        <h2>Страница не найдена</h2>
        <p>Вы попали на страницу, которой не существует, либо зашли по неверному адресу</p>
        <div :class="$style.buttonsWrap">
          <BaseButton :isNotMainColor="true" @click="goHome">
            <template #text>
              Перейти на главную страницу
            </template>
          </BaseButton>
          <br />
          <BaseButton @click="goToPreviousPage">
            <template #text> Перейти в предыдущий раздел </template>
          </BaseButton>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import BaseButton from '@/components/shared/buttons/BaseButton.vue';

export default {
  components: {
    BaseButton
  },

  methods: {
    goHome() {
      this.$router.push({ path: '/' });
    },

    goToPreviousPage() {
      this.$router.go(-1);
    }
  }
};
</script>

<style module lang="scss">
.wrap {
  height: 100%;
  max-width: 290px;
  margin: 0 auto;
  padding-top: 5rem;
}

.imageWrap {
  max-width: 290px;
  height: auto;
}

.image {
  width: 100%;
  height: auto;
}

.buttonsWrap {
  text-align: center;

  button {
    width: 100%;
  }
}

.contentWrap {
  padding-top: 1rem;
}

@media (min-width: 768px) {
  .wrap {
    max-width: 700px;
  }

  .contentWrap {
    padding-top: 0;
  }
}
</style>
